.Footer {
  flex-shrink: 0;
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Footer .poweredBy {
  margin-right: 8px;
}

.Footer .shortcutLink {
  margin-left: 8px;
  padding-right: 60px;
}

.Footer a {
  color: var(--text-primary);
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}
.Footer a:hover {
  font-weight: 700;
}
