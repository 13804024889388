.App {
  text-align: center;
  color: var(--text-primary);
  background-color: var(--background);
  transition: all 0.2s;

  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

.resultLinks {
  margin-top: 8px;
  font-size: 0.8rem;
}
.resultLinks a {
  color: var(--text-primary);
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}
.resultLinks a:hover {
  font-weight: 700;
}

.contents {
  flex: 1 0 auto;
  opacity: 0;
  transition: all 0.5s;
}

.contents-shown {
  opacity: 1;
}

.spacer {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  transition: all 0.5s;
}

.spacer-collapse {
  flex-grow: 0.0001;
}

.logo {
  font-family: 'Zen Old Mincho', serif;
  font-size: 2.5rem;
  margin: 8px 0;
}

.logoSmall {
  font-size: 2rem;
}

.SearchWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.searchWrapperInner {
  position: relative;
  width: 30%;
}

.searchInput {
  width: 100%;
  padding: 12px 0 12px 12px;
  border-radius: 4px 0 0 4px;
  font-size: 1.1rem;
  transition: all 0.5s;
  border: 1px solid rgb(226, 232, 240);
  box-sizing: border-box;
}

@media only screen and (max-width: 390px) {
  .searchWrapperInner {
    width: 80%;
  }
}

.searchInputSmall {
  font-size: 0.8rem;
}

.searchButton {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--text-primary);
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.5s;
  border-left: 1px solid rgb(226, 232, 240);
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  background-color: transparent;
  padding: 14px;
}

.searchButton:hover {
  background-color: rgb(226, 232, 240);
}

.searchButtonSmall {
  font-size: 0.8rem;
}

.toggleThemeButton {
  cursor: pointer;
}
