.ResultList .cardList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px;
}

.ResultList > h2 {
  cursor: pointer;
  font-family: 'Lato', sans-serif;
}
