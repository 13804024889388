.tooltip {
  border-radius: 0.3rem;
  padding: 0.25rem 0.5rem 0.3rem 0.5rem;
  background-color: var(--accent);
  color: var(--text-primary);
}

.themeIcon {
  background-color: var(--background);
}
