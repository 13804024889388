.Card {
  cursor: pointer;
  width: 200px;
  margin-right: 12px;
  margin-bottom: 12px;
  background: transparent;
  border-bottom: 1px solid transparent;
}

.Card:hover {
  border-bottom: 1px solid var(--text-primary);
}

.Card .cardImage {
  height: 300px;
  width: 200px;
  object-fit: cover;
  border-radius: 4px;
}

@media only screen and (max-width: 390px) {
  .Card {
    width: 170px;
  }
  .Card .cardImage {
    height: 255px;
    width: 170px;
  }
}

.Card .cardDetails {
  font-family: 'Lato', sans-serif;
  margin-bottom: 4px;
}

.Card .cardTitle {
  text-align: left;
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 0.9rem;
}

.Card .cardMeta {
  font-size: 0.8rem;
  font-weight: 300;
}

.Card > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Card .cardDetails > div {
  display: flex;
  flex-direction: column;
  text-align: left;
}
